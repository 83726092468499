import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Flag from 'react-world-flags';
import Flip from 'react-reveal/Flip';

import Layout from '../components/layout';
import SEO from '../components/seo';
import programmation from '../pdf/PROGRAMMATION-CINELANGUES-ALLEMAND.pdf';
import img from '../images/image_partenariat.jpg';

export const pageQuery = graphql`
  query {
    films: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/content/films/**/*.md" }
        frontmatter: { category: { eq: "Allemand" } }
      }
      sort: { order: DESC, fields: [frontmatter___time] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          html
          excerpt
          frontmatter {
            title
            meta
            time
            classe
            affiche {
              childImageSharp {
                fluid(quality: 100, maxWidth: 400, maxHeight: 540) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;

const AllemandPage = ({ data }) => (
  <Layout>
    <SEO
      title="Films Allemand 2020 - Cinelangues"
      description="Retrouvez tous nos films allemands."
      pathname="/allemand-les-films-cinelangues/"
    />
    <div className="page">
      <div className="container jumbotron">
        <div className="row">
          <div className="col-sm-12 col-md-5 header-content my-auto">
            <h1 className="ml-2">
              <Flag code="de" height="30" className="ml-2 mr-2" />
              Films Allemands
            </h1>
          </div>
          <div className="col-sm-12 col-md-7 my-auto text-right">
            <a
              type="button"
              className="mr-2 mt-1 ml-2"
              data-toggle="modal"
              data-target="#exampleModal"
              href="#"
            >
              <i className="fa fa-edit"></i>&nbsp;&nbsp;Présentation
            </a>
            <a
              href={programmation}
              target="_blank"
              className="mt-1"
              rel="norefer noopener"
            >
              <i className="fa fa-download"></i>&nbsp;&nbsp;Programmation PDF
            </a>
          </div>
        </div>
        <div
          className="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title mt-0">
                  Programmation Allemand 2023-2024
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p className="small">
                  <strong>Pour la saison la saison 2024-2025, Cinélangues vous propose une grande diversité de films qui abordent les thèmes les plus variés:</strong> les films historiques qui pourront faire l’objet d’une séquence à l’occasion des 35 ans de la chute du mur, des films dont les enfants sont les héros et des films sur des thématiques sociétales telle que la place et la fonction des technologies modernes ou le rôle de l’art dans l’identité culturelle d’un pays.
                </p>
                <p className="small">
                  Cette année encore, nous ne fonctionnerons pas avec une programmation trimestrielle mais avec une sélection de films à l’année, que vous pourrez programmer vous-mêmes quand vous le souhaitez, en fonction de vos choix pédagogiques.
                </p>
                <p className="small">
                  <strong>Sont programmés dès septembre quatre nouveaux films – La belle affaire</strong> de Natja Brunkhorst, un film historique sur la fin de la RDA; <strong>La salle des profs</strong> d’Ilker Catak au cœur d’un mystère dans un collège; <strong>Anselm</strong> de Wim Wenders un film biographique sur l’artiste Anselm Kiefer et <strong>Tom et Hacke</strong> de Norbert Lechner, un film d’aventures dans la Bavière d’après-guerre. 
                </p>
                <p className="small">
                  <strong>Au programme également, les cinq films de notre catalogue– Fritzi, Heidi, la Révolution silencieuse, Le vent de la liberté</strong> et <strong>I’m your man</strong> – que vous connaissez déjà et qui rencontrent toujours beaucoup de succès dans vos classes.
                </p>
                <p className="small">
                  <strong>Pour des projets en interdisciplinarité avec des collègues d’histoire, de philosophie, de musique ou d’histoire des arts,</strong> vous avez la possibilité de programmer, avec toute la préparation qui s’impose en amont, le film du Britannique Jonathan Glazer, <strong>La zone d’intérêts</strong>.
                </p>
                <p className="small">
                  Et enfin, nous n’oublions pas les fans d’Almanya, notre blockbuster Cinélangues que vous pouvez programmer à la demande.
                </p>
                <p className="small">
                  <strong>Nous espérons que la programmation 2024-2025 vous offrira de belles découvertes linguistiques et culturelles et nous vous remercions pour votre fidélité !</strong>
                </p>
                <p className="small">
                  <strong>Elsa JAGHOUAR</strong>
                  <br />
                  Chargée de la programmation allemand
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container films">
        <div className="row">
          <div className="col-lg-12">
            <div className="row no-gutter">
              {data.films.edges.map(({ node: post }) => (
                <div key={post.id} className="col-6 col-sm-6 col-md-4 col-lg-3">
                  <Flip right cascade>
                    <div className="card">
                      <Link to={post.fields.slug}>
                        <Img
                          fluid={post.frontmatter.affiche.childImageSharp.fluid}
                          alt={post.frontmatter.title}
                        />
                        <div className="text">
                          <div className="row no-gutter">
                            <h2 className="card-title text-capitalize col-12 col-sm-5 col-md-7">
                              <Flag code="de" height="11" />
                              {post.frontmatter.title}
                            </h2>
                            <div className="col-sm-7 col-md-5 badges">
                              {post.frontmatter.time != 'NA' ? (
                                <span className="badge badge-pill badge-danger">
                                  {post.frontmatter.time}
                                </span>
                              ) : null}
                              <span className="badge badge-pill badge-warning">
                                {post.frontmatter.classe}
                              </span>
                            </div>
                            <div className="col-lg-12">
                              <small>{post.frontmatter.meta}</small>
                            </div>
                            <p>
                              <div
                                dangerouslySetInnerHTML={{ __html: post.excerpt }}
                              ></div>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Flip>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default AllemandPage;
